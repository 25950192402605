module UploadService {
    let app = angular.module("app");

    app.factory("UploadService", ["$resource", "GlobalApplicationData", "FileUploader", ($resource: ng.resource.IResourceService, GlobalApplicationData: any, FileUploader:any) => {
        let serv = {
            upload: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/upload-file', {
            }, {
                    insert: {
                        method: 'POST',
                        isArray: false
                    }
                }),
            getAvatarUploader: () => {
                return new FileUploader({url: GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/upload-avatar'});
            },
            
            deleteUpload: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-upload-file', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }),
        };
        return serv;
    }]);
}